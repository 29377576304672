import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 70vh;
  background: #fff;
`;
export const InWrapper = styled.div`
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  max-width: 100vw;
  height: 100%;
  flex-direction: row;
  padding-top: 10%;
  padding-bottom: 10%;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Article = styled.article`
  width: 48%;
  font-size: 1.48rem;
  text-align: justify;
  letter-spacing: 1px;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 30px;
    font-size: 15pt;
  }

  @media (max-width: 680px) {
    font-size: 13pt;
  }
`;
