import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 76vh;
  padding-bottom: 20px;
  background: #c22c34;

  @media (min-width: 960px) {
    min-height: 56vh;
  }
`;
export const InWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  flex-direction: column;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Carousel = styled.div`
  width: 90%;
  height: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;

  @media( max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 960px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const Header = styled.h1`
  text-align: center;
  font-size: 42px;
  color: #fff;
  width: 100%;
  margin-top: 20px;
`;