import * as React from "react"
import { Description, Intro, Projects } from '../components/landing';
import { Layout } from '../components/common';
import SEO from '../components/seo';

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Strona główna" />
      <Intro />
      <Description />
      <Projects />
    </Layout>
  )
}

export default IndexPage
