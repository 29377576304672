import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, ProjectPastile, ProductPastile, TextBanner } from '../../common';
import { Wrapper, InWrapper, Header, Carousel } from './styles';


export const Projects = () => {

  const data = useStaticQuery(graphql`
  query MyQuery {
    allImageSharp(filter: {fluid: {originalName: {regex: "/past(1|2|3|4).jpeg/"}}}) {
      edges {
        node {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            sizes
            originalImg
            originalName
          }
        }
      }
    }
    site(polyfill: {}) {
      siteMetadata {
        homePastile {
          title
          description
        }
      }
    }
  }
   
  `);

  const {homePastile} = data.site.siteMetadata;

  return ( 
    <Wrapper>
      <InWrapper as={Container}>
      <Header>
          Realizujemy
       </Header>
       <Carousel>
        <ProjectPastile image={data.allImageSharp.edges[2]} info={homePastile[0]} />
        <ProjectPastile image={data.allImageSharp.edges[1]} info={homePastile[2]} />
        <ProjectPastile image={data.allImageSharp.edges[3]} info={homePastile[1]} />
        </Carousel>
      </InWrapper>
      <InWrapper as={Container}>
        <TextBanner />
      </InWrapper>
      <InWrapper as={Container}>
      <Header>
          Produkty
       </Header>
       <Carousel>
        <ProductPastile image={data.allImageSharp.edges[0]} info={homePastile[3]} />
        {/* <ProjectPastile image={data.allImageSharp.edges[1]} info={homePastile[1]} /> */}
        {/* <ProjectPastile image={data.allImageSharp.edges[0]} info={homePastile[2]} /> */}
        </Carousel>
      </InWrapper>
    </Wrapper>
  )
}