import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Container } from '../../common';
import { Wrapper, InWrapper, Article } from './styles';

export const Description = () => {

  const data = useStaticQuery(graphql`
  query homeDesc {
    site {
      siteMetadata {
        homeDescription
      }
    }
  }  
  `);
  const { homeDescription } = data.site.siteMetadata;

  return (
    <Wrapper>
      <InWrapper as={Container}>
        <Article>
          {homeDescription.substring(0, homeDescription.length/1.99)}
        </Article>
        <Article>
          {homeDescription.substring(homeDescription.length/1.95, homeDescription.length)}
        </Article>
      </InWrapper>
    </Wrapper>
  )
}