import React, { useEffect, useState } from 'react';
import { Header } from '../../theme';
import { Container } from '../../common';
import { Wrapper, IntroWrapper, Details } from './styles';

export const Intro = () => {

  const [wid, setWid] = useState(0);
  const [opa, setOpa] = useState(0);
  const [cnt, setCnt] = useState(0);
  const h1Arr = ['Jakość, perfekcja, pasja.', 'Nowoczesne rozwiązania.'];
  const h4Arr = ['Zrobimy to jak chcesz', 'Wiemy jak je wykorzystać'];

  useEffect(() => {
    setTimeout(() => {
      if(wid === 1) {
        setOpa(1);
        setWid(0);
        cnt === 0 ? setCnt(1) : setCnt(0);
      } else {
        setWid(1);
        setOpa(0);
      }
    }, 3000)
  })

  return ( 
    <Wrapper>
      <Header />
      <IntroWrapper as={Container}>
        <Details wid={wid} opa={opa}>
              <h1>{h1Arr[cnt]}</h1>
              <h4>{h4Arr[cnt]}</h4>
        </Details>
      </IntroWrapper>
    </Wrapper>
  )
}