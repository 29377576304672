import styled from 'styled-components';
import bgSample from '../../../images/0001.webp';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: #ff0000;
  background-image: url(${bgSample});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @media (max-width: 960px) {
    height: 100vh;
`;

export const IntroWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content:flex-start;
  max-width: 100vw;
  height: 100%;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Details = styled.div`
  position: relative;
  left: 0;
  background: #c22c34;
  min-width: 5px;
  width: ${props => props.wid === 1 ? 0 : 350}px;
  height: 40%;
  transition: width 1s linear;


  @media (max-width: 960px) {
    width: 90%;
    margin-top: 20%;
    margin-bottom: 2rem;
    display: block;
    left: 0;
  }

  @media (max-width: 680px) {
    width: 90%;
    margin-top: 50%;
    margin-bottom: 2rem;
    display: block;
    left: 0;
  }

  h1 {
    margin-top: 4.4rem;
    margin-left: 2rem;
    margin-bottom: 1.4rem;
    font-size: 36pt;
    width: 700px;
    color: #fff;
    opacity: ${props => props.opa === 1 ? 1 : 0};
    transition: opacity 0.5s linear 0.9s;


    @media (max-width: 960px) {
      margin-left: 1.4rem;
      mix-blend-mode: diffrence;
      width: 70%;
      font-size: 26pt;
    }

    @media (max-width: 680px) {
      margin-top: 3.6rem;
      margin-left: 0.4rem;
      font-size: 20pt;
      width: 100%;
    }
  }

  h4 {
    margin-left: 2rem;
    margin-bottom: 2.5rem;
    font-size: 30pt;
    font-weight: normal;
    width: 550px;
    color: #010101;
    // display: ${props => props.opa === 1 ? 'inline-block' : 'none'};
    opacity: ${props => props.opa === 1 ? 1 : 0};
    transition: opacity 0.5s linear 1.3s;

    @media (max-width: 960px) {
      mix-blend-mode: unset;
      width: 100%
      font-size: 16  pt;
    }

    @media (max-width: 680px) {
      margin-left: 1rem;
      font-size: 18pt;
      width:100%;
    }
  }
`;

export const Thumbnail = styled.div`
  flex: 1;
  @media (max-width: 960px) {
    width: 100%;
  }

  img {
    width :100%;
  }
`;